.react-terminal-line {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
}

.react-terminal-ui .terminal-output,
.react-terminal-ui .terminal-input {
  text-align: left;
}

.react-terminal-input,
.react-terminal-active-input {
  color: #FFA500;
}

.xterm-viewport {
  padding-top: 30px;
}

.xterm-rows {
  text-align: left !important;
  font-family: 'Roboto Mono', monospace; /* Apply the new font here */
}

.xterm .xterm-viewport::-webkit-scrollbar {
  width: 10px;
}

.xterm .xterm-viewport::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.xterm .xterm-viewport::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #1e1e1e;
}

.xterm .xterm-viewport::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#terminal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.xterm {
  flex: 1;
  width: 100%;
  height: 100%;
  font-family: 'Roboto Mono', monospace; /* Apply the new font here */
}

.xterm-screen {
  height: 100%;
}
